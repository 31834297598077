import { takeEvery, takeLatest } from "redux-saga/effects"
import { ACTIONS } from "../../enums/actions"
import authSaga from "./authSaga"
import clientOptionSaga from './clientOptionsSaga'
import userSaga from "./userSaga"
import timezoneSaga from "./timezoneSaga"
import resourceSaga from "./resourceSaga"
import awsSaga from './awsSaga'
import genericSaga, { genericGet, genericPost } from './genericSaga'
import { socketInitFlow, } from './socketSaga';

export default function* rootSaga() {

  //General
  yield takeLatest(ACTIONS.SHOW_ERROR, (action) =>
    genericSaga.showError(action));

  //Authentication
  yield takeLatest(ACTIONS.LOGIN, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/auth/login'), true, ACTIONS.LOGIN_SUCCESS));
  yield takeLatest(ACTIONS.LOGOUT, authSaga.logout)
  yield takeLatest(ACTIONS.REQUEST_PASSWORD_RESET, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'auth/request_password_reset')));
  yield takeLatest(ACTIONS.PASSWORD_RESET, authSaga.resetPassword)
  yield takeLatest(ACTIONS.INIT_SOCKET, socketInitFlow);

  //Client Options
  yield takeEvery(ACTIONS.SET_CLIENT_OPTIONS, clientOptionSaga.setclient_options)

  //Users
  yield takeLatest(ACTIONS.GET_USER, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/user/get-user')));
  yield takeLatest(ACTIONS.GET_USERS, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/user/get-all')));
  yield takeLatest(ACTIONS.CREATE_USER, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/user/create')));
  yield takeLatest(ACTIONS.UPDATE_USER, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/user/update')));
  yield takeLatest(ACTIONS.USER_ACTIVATION, userSaga.userActivation)
  yield takeLatest(ACTIONS.DOWNLOAD_USERS_REPORT, userSaga.downloadUsersReport)
  yield takeLatest(ACTIONS.GET_USER_ROLES, authSaga.getUserRolesByUser)
  yield takeLatest(ACTIONS.DOWNLOAD_USERS_REPORT, userSaga.downloadUsersReport)
  yield takeLatest(ACTIONS.ADD_REMOVE_ROLE, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/user/add-remove-role')));
  yield takeLatest(ACTIONS.GET_ROLES, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/role/get-all')),
);

  //Timezone
  yield takeLatest(ACTIONS.SET_TIMEZONE, timezoneSaga.setTimeZone)

  //Resources
  yield takeLatest(ACTIONS.CREATE_UPLOAD_RESOURCE, resourceSaga.createUploadResource)
  yield takeLatest(ACTIONS.UPLOAD_RESOURCE_FILE, resourceSaga.uploadResourceFile)
  yield takeEvery(ACTIONS.CONVERT_RESOURCES, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, "api/resource/convert")));
  yield takeEvery(ACTIONS.GET_MY_PROFILE, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, "api/resource/get-my-profile"))
);

  //AWS
  yield takeLatest(ACTIONS.INIT_PRESIGNED_URL_MULTIPART_UPLOAD, awsSaga.initPresignedUrlMultipartUpload)
  yield takeLatest(ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD, awsSaga.initPresignedUrlSimpleUpload)
  yield takeLatest(ACTIONS.COMPLETE_MULTIPART_UPLOAD, awsSaga.completeMultiPartUpload)
  yield takeLatest(ACTIONS.CONFIRM_EMAIL, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/auth/confirm-email')));

  //Socket
  yield takeLatest(ACTIONS.SYNC_DATA_FROM_SOCKET, genericSaga.socketReceive);

  //Get global setting
  yield takeLatest(ACTIONS.GET_GLOBAL_SETTINGS, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      return genericGet(data, `api/global-settings/get`);
    })
  );

  //Update global setting
  yield takeLatest(ACTIONS.UPDATE_GLOBAL_SETTINGS, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      return genericPost(data, `api/global-settings/update`);
    })
  );


}
