import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../interfaces/store';
import { getUser } from '../../../store/actions/userActions';
import Loading from '../../../components/general/Loading';
import { COLORS } from '../../../shared/constants/layout-constants';

const authContStyle = {
  marginTop: 16,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const iconStyle = {
  color: COLORS.BRAND_PRIMARY,
  fontSize: 24,
  cursor: 'pointer',
};

function UserViewPage(props: any) {
  const params = useParams<{ userId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({} as any);
  const [userFetched, setUserFetched] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isNaN(Number(params.userId))) {
      dispatch(getUser({ user_id: params.userId }, () => setUserFetched(true)));
    }
  }, []);

  const users = useSelector((state: IRootState) => state.main.users);

  useEffect(() => {
    if (userFetched) {
      const matchedUser = Object.values(users).find((x) => x.id === Number(params.userId)) as any;

      if (!matchedUser) {
        navigate('/Users');
      } else {
        setUser(matchedUser);
        setLoading(false);
      }
    }
  }, [users, userFetched]);

  <Loading loading={loading} />;

  if (loading) {
    return <div></div>;
  }
  
  return (
    <>
      {user && <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <b>User Info</b>
        <Box>
          {JSON.stringify(user, null, 2)}
        </Box>
      </Box>}
    </>
  );
}

export default UserViewPage;
