import { normalize } from 'normalizr';
import { call, put } from 'redux-saga/effects';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store';
import { HttpResponse } from '../../interfaces/store/api/HttpResponse';
import { dateFormatted } from '../../shared/utils/date-utils';
import { GetFriendlyError } from '../../utils/general';
import fileSaver from "file-saver";
import { userSchema } from './schema';


const client = new HttpClient();

async function downloadUsersReportAPI(data: any) {
	try {
		const response = await client.getFile('api/user/download-users-report' + objectToQuery(data));
		return response;
	} catch (e) {
		return e;
	}
}

async function userActivationAPI(
	data: any,
): Promise<HttpResponse<{ x: string }>> {
	return client.get('api/user/activation' + objectToQuery(data));
}

const userSaga = {

	* userActivation(action: IDispatchAction): Generator {
		try {
			const response = (yield call(userActivationAPI, action.payload)) as HttpResponse<any>;
			if (response.status === 200 || response.status === 201) {
				yield put({
					type: 'USER_ACTIVATION_SUCCESS',
					normalized: normalize(response.data.user, userSchema),
				})
				if (action.onSuccess) {
					action.onSuccess();
				}
			}
		}
		catch (e) {
			if (action.onFail) {
				action.onFail();
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

	* downloadUsersReport(action: IDispatchAction): Generator {
		try {

			const response = (yield call(downloadUsersReportAPI, action.payload)) as HttpResponse<any>;;
			let filename = `user-report-${dateFormatted(new Date(), 'yyyymmdd', '-')}.csv`;
			fileSaver.saveAs(response.data as any, filename);
			if (action.onSuccess) {
				action.onSuccess();
			}
		}
		catch (e) {
			if (action.onFail) {
				action.onFail();
			}
			const error = e as Error;
			const friendlyError = GetFriendlyError(error.message);
			yield put({ type: ACTIONS.ERROR, message: friendlyError });
		}
	},

};

export default userSaga;
