import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store';
import { IInitPresignedUrlMultipartUploadPayload, IInitPresignedUrlSimpleUploadPayload, ICompleteMultipartUploadPayload } from '../../shared/interfaces/requests/aws.interface';

export const initPresignedUrlMultipartUpload = (
  payload: IInitPresignedUrlMultipartUploadPayload,
  onSuccess?: () => void,
  onFail?: () => void,
  onProgress?: (percent: number) => void
): IDispatchAction<IInitPresignedUrlMultipartUploadPayload> => ({
  type: ACTIONS.INIT_PRESIGNED_URL_MULTIPART_UPLOAD,
  payload,
  onSuccess,
  onFail,
  //onProgress
});

export const initPresignedUrlSimpleUpload = (
  payload: IInitPresignedUrlSimpleUploadPayload,
  onSuccess?: () => void,
  onFail?: () => void,
  onProgress?: (percent: number) => void
): IDispatchAction<IInitPresignedUrlSimpleUploadPayload> => ({
  type: ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD,
  payload,
  onSuccess,
  onFail,
  //onProgress
});

export const completeMultipartUpload = (
  payload: ICompleteMultipartUploadPayload,
  onSuccess?: () => void,
  onFail?: () => void,
  onProgress?: (percent: number) => void
): IDispatchAction<ICompleteMultipartUploadPayload> => ({
  type: ACTIONS.COMPLETE_MULTIPART_UPLOAD,
  payload,
  onSuccess,
  onFail,
 // onProgress
});
