export enum RESOURCE_TYPE {
	IMAGE = 'image',
	VIDEO = 'video',
	AUDIO = 'audio',
	OTHER = 'other'
  }
  
  export enum RESOURCE_STATUS {
	AWAITING_CONVERSION = 'awaiting_conversion',
	UNAVAILABLE = 'unavailable',
	AVAILABLE = 'available',
	DELETED = 'deleted',
	ERROR = 'error',
  }
  
  export const FILE_CHUNK_SIZE = 7340032;
  
  export enum IMAGE_FIT {
	COVER = 'cover', 
	CONTAIN = 'contain' ,
	FILL = 'fill', 
	INSIDE = 'inside', 
	OUTSIDE = 'outside' 
  }
  
  export enum IMAGE_EXTENSION {
	'jpeg',
	'png',
	'webp'
  }
  
  export enum UPLOAD_COLLECTION_METHOD {
	REPLACE = 'replace',
	INCREMENT = 'increment'
  }