import { useNavigate  } from 'react-router-dom';
import { useEffect } from 'react';

function ScrollToTop() {
    // const history = useNavigate ()
    // useEffect(() => {
    //   const unlisten = history.listen((location, action) => {
    //     if (action !== 'POP') {
    //       window.scrollTo(0, 0)
    //     }
    //   })
    //   return () => unlisten()
    // }, [])
    // return (null)
	return null
  }

export default ScrollToTop