/* eslint-disable no-console */
import { Action } from 'redux';
import { EventChannel, eventChannel } from 'redux-saga';
import { call, delay, fork, put, race, take } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store';
import { genericWebsocketReceive } from './genericSaga';
import { io } from "socket.io-client";

let globalSocket: any;
// eslint-disable-next-line @typescript-eslint/no-var-requires

function connect(): Promise<any> {
  console.log("connect to socket at: " + process.env.REACT_APP_SOCKET_HOST)
  globalSocket = io(process.env.REACT_APP_SOCKET_HOST || "ws://localhost:3001", {transports: ['websocket']})
  return new Promise((resolve) => {
    globalSocket.on('connect', () => {
      console.log('Connect Socket::::::');
      securedSocket(globalSocket, 'user/join-personal-room', {});
      resolve(globalSocket);
    });
    globalSocket.on('connect_error', (err: any) => {
      console.log(`connect_error due to ${err.message}`);
    });
  });
}

function socketSubscribe(socket: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return eventChannel((emit) => {
    socket.on('exception', (err: any) => {
      emit({ type: ACTIONS.SOCKET_MESSAGE_ERROR, payload: err });
      console.log('an exception happened', err);
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });
}

function securedSocket(socket: any, message: string, data: Record<string, unknown>) {
  const jwtToken = localStorage.getItem('jwt');
  let dataWithJwt = { jwt: jwtToken } as any;
  if (data) {
    dataWithJwt = { jwt: jwtToken, ...data };
  }

  console.log("SHOULD EMIT",  message, dataWithJwt)
  socket.emit(message, dataWithJwt);
}

function disconnect(): void {
  globalSocket.disconnect();
}

export function* read(socket: any, subscription: any): Generator {
  const channel = (yield call(subscription, socket)) as EventChannel<any>;
  while (true) {
    const action = (yield take(channel)) as Action;
    yield put(action);
  }
}

export function* disconnectSocket(action: IDispatchAction): Generator {
  yield call(disconnect);

  if (action.onSuccess) {
    action.onSuccess();
  }
}

export function* socketInitFlow(action: IDispatchAction): Generator {
  const socket = yield call(connect);
  yield fork(read, socket, socketSubscribe);
  yield fork(read, socket, genericWebsocketReceive);
  if (action.onSuccess) {
    action.onSuccess();
  }
}

export function* responseHandler<ResponsePayload>(
  action: ACTIONS,
  onSuccess?: <T = unknown>(data: T) => void,
  onFail?: () => void
): Generator {
  const { timeout, error, response } = (yield race({
    error: take(ACTIONS.SOCKET_MESSAGE_ERROR),
    response: take(action),
    timeout: delay(5000)
  })) as {
    response: IDispatchAction<ResponsePayload>;
    timeout: boolean;
    error: IDispatchAction<unknown>;
  };

  if (timeout) {
    if (onFail) {
      onFail();
    }
  } else if (error) {
    if (onFail) {
      onFail();
    }
    yield put({ type: ACTIONS.ERROR, data: error });
  } else {
    if (onSuccess) {
      // console.log('response: ', response);
      onSuccess(response.payload);
    }
  }
}