
import { put } from "redux-saga/effects"; //call

const client_optionSaga = {
    * setclient_options(action: any): Generator {
        try {
            yield put({
                type: "SET_CLIENT_OPTIONS_SUCCESS",
                normalized: {
                    entities: {
                        client_options: {...action.payload.client_options, updated: new Date()}
                    }
                }
            });

            if (action.onSuccess) {
                action.onSuccess();
            }
        } catch (e) {
            if (action.onFail) {
                action.onFail();
            }
        }
    },

}

export default client_optionSaga;