import { Button } from '@mui/material';
import React, { useState } from 'react';
import ImageIcon from '@mui/icons-material/ImageOutlined'
import FileIcon from '@mui/icons-material/FilePresentOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';

interface IStandardUploadInputProps {
    initialImage?: string
    handleSubmit: (data: any) => void
    infoText?: string
    onFileSelected?: (data: any) => void
    showUploadButton?: boolean
    showPreview?: boolean
    accept?: string
    multiple?: boolean
    uploadType?: string
    forceKey?: any
}

export const StandardUploadInput: React.FC<IStandardUploadInputProps> = (props): JSX.Element => {

    const hiddenFileInput = React.useRef<any>(null);

    const handleClick = (event: any) => {
        hiddenFileInput!.current!.click();
    };

    const imageSrc = () => {
        if (props.initialImage) {
            return props.initialImage + '?' + new Date().getTime();
        }
        return '';
    };

    const [selectedFiles, setSelectedFiles] = useState<FileList>();

    return (
        <>
            {props.uploadType === 'images' && <Button style={{ marginBottom: 8 }} variant="contained" startIcon={<ImageIcon />} onClick={handleClick} >
                Select Image
            </Button>}
            {props.uploadType === 'files' && <Button style={{ marginBottom: 8 }} variant="contained" startIcon={<FileIcon />} onClick={handleClick} >
                Select File
            </Button>}
            {props.uploadType === 'profile' &&

                <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                    {props.initialImage ?
                        <span>
                            <img
                                className={'avatar'}
                                src={props.initialImage + "&forceKey=" + props.forceKey || 'none'}
                            />
                        </span>
                        : <div className={'userImageWrapper'}>
                            <PermIdentityOutlinedIcon />
                        </div>}
                </div>
            }

            <span style={{ fontSize: 12 }}>{props.infoText || ''}</span>
            <div className="flex flex-col items-center mt-2">
                <label
                    htmlFor="picture"
                    className="px-5 py-1 mt-6 bg-white border rounded-lg shadow cursor-pointer hover:bg-purple-600 hover:text-white"
                >
                    {/* <span className="mt-2 text-base leading-normal">{selectedFile?.name || ''}</span> */}
                    <input
                        ref={hiddenFileInput}
                        multiple={props.multiple || true}
                        type="file"
                        accept={props.accept || ".png, .jpg, .jpeg"}
                        id="picture"
                        name="picture"
                        className="hidden"
                        onChange={(e) => {
                            if (e.target.files) {
                                setSelectedFiles(e.target.files);
                                if (props.onFileSelected) {
                                    props.onFileSelected(e.target.files)
                                }
                            }
                        }}
                        style={{ display: 'none' }}
                    />
                </label>
            </div>
            {props.showPreview && <div>
                <img style={{ width: 200, height: 150, backgroundColor: '#d1d1d1' }} src={imageSrc()} />
            </div>}
            {props.showUploadButton && <Button variant={'outlined'} onClick={() => {
                props.handleSubmit(selectedFiles)
            }}>
                Submit
            </Button>
            }
        </>
    );
};
