import { Container } from "@mui/material"

import ScrollToTop from '../../../components/general/ScrollToTop'
function Terms(props: any) {


	return (
		<>
			<ScrollToTop />
			<Container maxWidth="lg">

				<h2>
					303-framework Terms of Use
				</h2>

			</Container>
		</>
	)

}

export default Terms