import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/authActions";

const ForceLogout: React.FC<any> = (props) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(logout({}))
	}, [])

	return (<div></div>)
};

export default ForceLogout;
