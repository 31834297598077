import React, { useEffect, useState } from 'react';
import { StandardUploadInput } from '../../../components/general/StandardUploadInput';
import { useSelector } from '../../../store';
import { getFileExtension } from '../../../shared/utils/file';
import { useSnackbar } from 'notistack';
import { IMAGE_FIT, RESOURCE_TYPE, UPLOAD_COLLECTION_METHOD } from '../../../shared/constants/resource.constants';
import { useDispatch } from 'react-redux';
import { getMyProfile, uploadResourceFile } from '../../../store/actions/resourceActions'
import Loading from '../../../components/general/Loading';
import { setClientOptions } from '../../../store/actions/clientOptionsActions';
import { getS3URL } from '../../../utils/resource';
function AccountViewPage(props: any) {

    const [processing, setProcessing] = useState(false);
    const resources = useSelector((state) => Object.values(state.main.resources));
    const my_user = useSelector((state) => state.main.my_user);
    const [refreshKey, setRefreshKey] = useState(0);

    const profileUrl = getS3URL(resources, my_user?.id || -1, 'profile', 'thumb', false, false);

    useEffect(() => {
        if (!profileUrl) {
            dispatch(getMyProfile({}, (data) => {console.log("profile response", data)}));
        }
    }, []);

    let reloadTimeout = null as any;

    const setNewTimeout = () => {
        clearTimeout(reloadTimeout);
        reloadTimeout = setTimeout(() => {
            dispatch(getMyProfile({}));
            setRefreshKey(refreshKey + 1);
        }, 500)
    }

    useEffect(() => {
        return () => clearTimeout(reloadTimeout);
    }, []);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const handleSubmit = (selectedFile: File) => {

        return new Promise(function (resolve, reject) {
            setProcessing(true);
            try {
                getFileExtension(selectedFile!.name);
            } catch (e) {
                enqueueSnackbar(`Invalid File Extension.`, { variant: 'error' });
                return;
            }

            const uploadData = {
                name: my_user?.id,
                model: 'profile',
                modelId: my_user?.id,
                extension: getFileExtension(selectedFile!.name),
                filename: selectedFile!.name,
                fileSize: selectedFile!.size,
                uploadCollectionMethod: UPLOAD_COLLECTION_METHOD.REPLACE,
                type: RESOURCE_TYPE.IMAGE,
                parts: 1,
                file: selectedFile,
                rootPath: "private",
                primary: true,
                conversions: [
                    {
                        width: 256,
                        height: 256,
                        fit: IMAGE_FIT.COVER,
                        imageType: getFileExtension(selectedFile!.name),
                        appendName: "thumb",
                        conversion: "thumb",
                        rootPath: "private"
                    }
                ]
            };

            console.log("upload data", uploadData)

            dispatch(
                uploadResourceFile(
                    uploadData,
                    (data: any) => {
                        setProcessing(false);
                        enqueueSnackbar(`Image uploaded.`, { variant: 'success' });
                        setNewTimeout();
                        return resolve(true);
                    },
                    () => {
                        setProcessing(false);
                        enqueueSnackbar(`Image upload failed.`, { variant: 'error' });
                        return resolve(true);
                    },
                ),
            );
        })


    };

    return (
        <>
            <Loading loading={processing} />
            <h2>{my_user?.first_name + " " + my_user?.last_name}</h2>
            <b>{my_user.email}</b>
            <br /><br />
            <div key={refreshKey}>
                <StandardUploadInput
                    uploadType="profile"
                    initialImage={profileUrl}
                    handleSubmit={() => { }}
                    forceKey={refreshKey}
                    onFileSelected={(selectedFiles: FileList) => { handleSubmit(selectedFiles[0]) }} />
            </div>
        </>
    )
}

export default AccountViewPage