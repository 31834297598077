import { Alert, AlertTitle } from '@mui/material';
interface ICustomAlertProps {
  title: string;
  message: string;
  severity: 'error' | 'warning' | 'info' | 'success';
}

const CustomAlert: React.FC<ICustomAlertProps> = (props) => {
  return (
    <Alert sx={{ margin: '16px 0', alignItems: 'center' }} severity={props.severity}>
      <AlertTitle>{props.title}</AlertTitle>
      {props.message}
    </Alert>
  );
};

export default CustomAlert;
