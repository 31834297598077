export const USER_SERVICE = "USER_SERVICE";

export enum PERMISSION {
  Manage = "manage", //Wildcard meaning all permissions/actions
  Add = "add",
  Read = "read",
  Update = "upd",
  Delete = "del",
}

export const ROLE_NAMES = {
  ADMIN: "ADMIN",
};

export const validPasswordRegEx = new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\\S+$).{6,}$");


export const passwordInvalidString =
  "Minimum length of 6, 1 capital and 1 number.";

  export enum SUBJECT_ENTITY {
    User = "User",
    Log = "Log",
    Resource = "Resource",
    Role = "Role",
    UserRole = "UserRole",
    Token = "Token",
  }
  
  export enum USER_ACTIONS {
    update_location = "update_location",
    update_role = "udpate_role",
  }
  
  export const ACTIONS_BY_SUBJECT = {
    [SUBJECT_ENTITY.User]: USER_ACTIONS,
  };
  
  export const ACTIONS = {
    ...PERMISSION,
    ...USER_ACTIONS,
  };
  
  export type ACTIONS = PERMISSION | USER_ACTIONS;
