import { ACTIONS } from '../../enums/actions';

export const initSocket = (onSuccess?: any) => ({
  type: ACTIONS.INIT_SOCKET,
  onSuccess
});

export const disconnectSocket = (onSuccess?: any) => ({
  type: ACTIONS.DISCONNECT_SOCKET,
  onSuccess
});