import { ACTIONS } from '../../enums/actions';
import { ICreateUploadResourcePayload } from '../../shared/interfaces/model/resource.interfaces';

export const createUploadResource = (
  payload: ICreateUploadResourcePayload,
  onSuccess?: () => void,
  onFail?: () => void
) => ({
  payload,
  type: ACTIONS.CREATE_UPLOAD_RESOURCE,
  onSuccess,
  onFail
});

export const convertResources = (payload: any, onSuccess?: (data: any) => void, onFail?: () => void) => ({
	payload,
	type: ACTIONS.CONVERT_RESOURCES,
	onSuccess,
	onFail,
  });

  export const uploadResourceFile = (payload: any, onSuccess?: (data: any) => void, onFail?: () => void) => ({
	payload,
	type: ACTIONS.UPLOAD_RESOURCE_FILE,
	onSuccess,
	onFail,
  });

export const getMyProfile = (payload: any, onSuccess?: (data: any) => void, onFail?: () => void) => ({
	payload,
	type: ACTIONS.GET_MY_PROFILE,
	onSuccess,
	onFail,
});

export const getUploadUrls = (
	payload: any,
	onSuccess?: (data: any) => void,
	onFail?: () => void,
	onProgress?: (percent: number) => void
) => ({
	payload,
	type: ACTIONS.GET_UPLOAD_URLS,
	onSuccess,
	onFail,
	onProgress
});