import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  Card,
  TextField,
  Typography,
  CardContent,
} from "@mui/material";
import Loading from "../../../components/general/Loading";
import { logout, resetPassword } from "../../../store/actions/authActions";
import { useNavigate  } from "react-router-dom";
import { getUrlParam } from "../../../utils/general";
import { IRootState } from "../../../interfaces/store";
import { validPasswordRegEx } from "../../../shared/constants/user.constants";

interface IResetPasswordProps {}

const ResetPassword: React.FC<IResetPasswordProps> = () => {

	
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmError, setConfirmError] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [processing, setProcessing] = useState(false);
//   const recaptchaRef = useRef<ReCAPTCHA>(null);

  const user = useSelector(
    (state: IRootState) => state.main.my_user
  );

  useEffect(() => {
    if (user?.id){
      dispatch(logout({}))
    }
  }, [user])


  const token = getUrlParam('token')

  const handleCaptchaChange = (value?: string | null) => {
    setCaptcha(value as string);
  };

  const validatePassword = () => {
    const isValid = validPasswordRegEx.test(password)
    setPasswordError(!isValid)
  }

  const validateConfirm = () => {
    const hasConfirmError = confirmPassword !== password
    setConfirmError(hasConfirmError)
  }

  const onSubmit = () => {
    if (!captcha){
      enqueueSnackbar(
        "reCAPTCHA is required.",
        { variant: "error" }
      );
    }
    else if (!password.length){
      enqueueSnackbar(
        "Invalid Password.",
        { variant: "error" }
      );  
    }
    else if (password !== confirmPassword){
      enqueueSnackbar(
        "Password and confirm password must match.",
        { variant: "error" }
      );
    } else {
      setProcessing(true);
      dispatch(
        resetPassword(
          { password, token, captcha },
          (response: any) => {
            setProcessing(false);
            enqueueSnackbar(
              "Password has been reset",
              { variant: "success" }
            );

            const adminRole = response.user_roles.find((x: any) => x.name === 'Admin');
            // direct admins to login
            if (adminRole){
              navigate("/Dashboard")
            }
            else{ // direct regular users to page instructing them to login on their phone
              navigate("/PhoneLogin")
            }

          },
          () => {
            enqueueSnackbar(
              "Something went wrong, please request another reset token.",
              { variant: "error" }
            );  
            setProcessing(false);
          }
        )
      );
    }

  };

  return (
    <div>
      <Loading loading={processing} />
      <Card style={{ width: 400 }}>
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <Typography color="textSecondary" gutterBottom variant="h5">
            Reset Password
          </Typography>
          <TextField
            error={passwordError}
            helperText={passwordError ? "Minimum length of 6, 1 capital and 1 number." : ""}
            label="New Password"
            fullWidth={true}
            size="small"
            onChange={(event) => setPassword(event?.target.value)}
            onBlur={(event) => validatePassword()}
            style={{ marginTop: "2rem" }}
            value={password}
            variant="outlined"
            type={'password'}
          />
          <TextField
            error={confirmError}
            helperText={confirmError ? "Confirm password must match password." : ""}
            label="Confirm Password"
            fullWidth={true}
            size="small"
            onChange={(event) => setConfirmPassword(event?.target.value)}
            onBlur={(event) => validateConfirm()}
            style={{ marginBottom: "2rem", marginTop: "2rem" }}
            value={confirmPassword}
            variant="outlined"
            type={'password'}
          />
          <ReCAPTCHA
            onChange={(event) => handleCaptchaChange(event)}
            // ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
          />
          <Button
            onClick={() => onSubmit()}
            style={{ marginTop: "2rem" }}
            variant="outlined"
          >
            Submit
          </Button>
        </CardContent>
      </Card>
    </div>

  );
};

export default ResetPassword;
