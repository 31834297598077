import { RESOURCE_TYPE, FILE_CHUNK_SIZE } from '../constants/resource.constants';
export const getFileParts = (file: File): number => {
  const parts = Math.ceil(file.size / FILE_CHUNK_SIZE);
  return parts;
};

export const getFileExtension = (fileNameRaw: string): string => {
  const fileName = fileNameRaw.toLowerCase();
  const match = fileName.match(/[0-9a-z]+$/);
  if (!match || !match[0]) {
    console.log('Not a valid filename');
    throw new Error('Not a valid filename');
  }
  return match[0];
};

export const getFileType = (fileName: string): RESOURCE_TYPE => {
  const fileExtension = getFileExtension(fileName);
  const audioExtensions = ['mp3', 'wav', 'ogg', 'aac'];
  const videoExtensions = ['webm', 'mp4', 'mov', 'mpeg', 'mpg', 'avi', 'flv'];
  const imageExtensions = ['jpg', 'jpeg', 'png', 'tiff', 'svg', 'gif'];

  if (audioExtensions.indexOf(fileExtension) > -1) {
    return RESOURCE_TYPE.AUDIO;
  }

  if (imageExtensions.indexOf(fileExtension) > -1) {
    return RESOURCE_TYPE.IMAGE;
  }

  if (videoExtensions.indexOf(fileExtension) > -1) {
    return RESOURCE_TYPE.VIDEO;
  }

  throw new Error('Filetype is not supported');
};
