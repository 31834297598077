import axios from 'axios';
import { eventChannel } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store';
import { HttpResponse } from '../../interfaces/store/api/HttpResponse';
import { FILE_CHUNK_SIZE } from '../../shared/constants/resource.constants';
import { IInitPresignedUrlMultipartUploadPayload, IInitPresignedUrlSimpleUploadPayload } from '../../shared/interfaces/requests/aws.interface';

export function awsSubscribe(socket: any) {
  return eventChannel((emit) => {
    socket.on('aws/get-presigned-upload-urls-success', (data: unknown) => {
      emit({ type: ACTIONS.GET_PRESIGNED_UPLOAD_URLS_SUCCESS, payload: data });
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => { };
  });
}

async function uploadMultipartFile(
  file: File,
  urls: string | string[],
  setProgress: (percent: number) => void = () => null
): Promise<unknown> {
  const httpClient = axios.create();
  delete httpClient.defaults.headers.put['Content-Type'];

  const fileSize = file.size;

  const resParts = [];
  let partNumber = 0;

  for (let start = 0; start < fileSize; start += FILE_CHUNK_SIZE) {
    let end = start + FILE_CHUNK_SIZE;
    if (end > fileSize) {
      end = fileSize;
    }
    const blob = file.slice(start, end);

    const part = await httpClient.put(urls[partNumber], blob, {
      onUploadProgress: (progressEvent) => {
        const { loaded } = progressEvent;
        const totalLoaded = loaded + FILE_CHUNK_SIZE * partNumber;
        const percent = Math.floor((totalLoaded / fileSize) * 100);
        if (setProgress) {
          setProgress(percent);
        }
      }
    });

    partNumber += 1;

    resParts.push({
      ETag: (part as any).headers.etag,
      PartNumber: partNumber
    });
  }

  return resParts;
}

async function uploadSimpleFile(
  file: File,
  url: string,
  setProgress: (percent: number) => void = () => null
): Promise<unknown> {

  console.log("send it axios", url, file)
  let result = null;
  try {
    result = await axios.put(url, file, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentage = Math.floor((loaded * 100) / total!);
        if (setProgress) {
          setProgress(percentage);
        }
      }
    });
  }
  catch (e) {
    console.log(e)
  }

  return result

}

const awsSaga = {
  *initPresignedUrlMultipartUpload(action: IDispatchAction): Generator {
    // TODO: Error handling.
    const payload = action.payload as IInitPresignedUrlMultipartUploadPayload;
    const response = yield call(uploadMultipartFile, payload.file, payload.urls, action.onProgress);

    yield put({ type: ACTIONS.INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS, payload: response });

    if (action.onSuccess) {
      action.onSuccess();
    }
  },

  *initPresignedUrlSimpleUpload(action: IDispatchAction): Generator {
    const payload = action.payload as IInitPresignedUrlSimpleUploadPayload;

    console.log("payload initPresignedUrlSimpleUpload", payload);

    const response = (yield call(
      uploadSimpleFile,
      payload.file,
      payload.url,
      //   action.onProgress
    )) as HttpResponse<unknown>;
    if (response.status === 200 || response.status === 201) {
      yield put({ type: ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS, payload: response });
      if (action.onSuccess) {
        action.onSuccess();
      }
    } else {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *completeMultiPartUpload() {
    // call an endpoint on server telling amazon that the upload is complete

  }
};

export default awsSaga;
