
import { useEffect, useState } from 'react';
import css from '../../../SharedStyles.module.scss'
import { Tabs, Tab, Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Button } from '@mui/material';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { MARGIN_LARGE, MARGIN_MEDIUM, THEME_NAME } from '../../shared/constants/layout-constants';
import { TabPanel } from '../general/TabShared';
import { getGlobalSettings, updateGlobalSettings } from '../../store/actions/globalSettingsActions';

function GlobalSettings(props: any) {

    const [selectedTab, setSelectedTab] = useState(0);

    const globalSettings = useSelector((state: any) => state.main.global_settings);
    const theme = useSelector((state: any) => state.main.theme);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            theme: globalSettings.theme,
        },
        validationSchema: Yup.object().shape({
            theme: Yup.string().required("Required"),
        }),
        validate: (values: any) => {
            const errors = {} as any;
            return errors;
        },
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    return (
        <div>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={selectedTab}
                    onChange={(event: React.SyntheticEvent, newValue: number) => {
                        setSelectedTab(newValue as any);
                    }}
                    aria-label="Global Settings"
                >
                    <Tab label="General" />
                    {/* <Tab label="Other" /> */}
                </Tabs>
            </Box>

            <TabPanel value={selectedTab as any} index={0}>
                <div style={{ marginTop: MARGIN_LARGE }}>
                    <FormControl>
                        <FormLabel style={{color: theme.colors.font}}>Theme Settings</FormLabel>
                        <RadioGroup name="theme" value={formik?.values?.theme}>
                        <FormControlLabel
                                    key={0}
                                    value={THEME_NAME.LIGHT}
                                    defaultChecked={formik?.values?.theme === THEME_NAME.LIGHT}
                                    control={<Radio style={{color: theme.colors.accent_primary}} onClick={() => {
                                        formik.setFieldValue("theme", THEME_NAME.LIGHT);
                                    }} />}
                                    label={"Light"}
                                />
                                <FormControlLabel
                                    key={1}
                                    value={THEME_NAME.DARK}
                                    defaultChecked={formik?.values?.theme === THEME_NAME.DARK}
                                    control={<Radio style={{color: theme.colors.accent_primary}}  onClick={() => {
                                        formik.setFieldValue("theme", THEME_NAME.DARK);
                                    }} />}
                                    label={"Dark"}
                                />
                        </RadioGroup>
                    </FormControl>
                </div>
                <Button style={{marginTop: MARGIN_MEDIUM}} variant={'outlined'} onClick={() => {
                    dispatch(updateGlobalSettings({theme: formik?.values?.theme}, () => {}, () => {}, 
                    "Global Settings Updated", true));
                }}>
                    Save
                </Button>
            </TabPanel>
            <TabPanel value={selectedTab as any} index={1}>
                <div style={{ marginTop: MARGIN_LARGE }}>
                    Theme Settings
                </div>
            </TabPanel>
        </div>


    )
}

export default GlobalSettings