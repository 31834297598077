
import { useEffect } from 'react';
import GlobalSettings from './GlobalSettings';
import { useDispatch } from 'react-redux';
import { getGlobalSettings } from '../../store/actions/globalSettingsActions';
import { useSelector } from '../../store';

function GlobalSettingsPage(props: any) {

    const globalSettings = useSelector((state: any) => state.main.global_settings);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getGlobalSettings({}));
    }, [])

    console.log("globalSettings", globalSettings)
    return (
        <div>
            {globalSettings?.id && <GlobalSettings />}
        </div>
    )
}

export default GlobalSettingsPage