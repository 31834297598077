
import { put } from "redux-saga/effects"; //call
import { IDispatchAction } from "../../interfaces/store";

const timezoneSaga = {
    * setTimeZone(action: IDispatchAction): Generator {
        try {
            yield put({
                type: "UPDATE_TIMEZONE",
                payload: action.payload
            });

            if (action.onSuccess) {
                action.onSuccess();
            }
        } catch (e) {
            if (action.onFail) {
                action.onFail();
            }
        }
    },

}

export default timezoneSaga;