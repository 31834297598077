import { IResource } from "../shared/interfaces/model/resource.interfaces";

export const preparePublicAWSUrl = (resource: IResource): string => {
  const url =
    "https://" +
    process.env.REACT_APP_AWS_BUCKET +
    ".s3.us-west-2.amazonaws.com/" +
    resource.path +
    "/" +
    resource.name +
    "." +
    resource.extension +
    "?" +
    new Date().getTime();
  return url;
};

export const getS3URL = (
  imageResources: IResource[],
  model_id: number,
  model: string,
  preferedConversion: string,
  primaryOnly: boolean,
  isPublic = true
) => {
  const original = imageResources.find(
    (x: IResource) =>
      x.model_id === model_id &&
      x.model === model &&
      x.conversion === "original" &&
      (!primaryOnly || x.primary === true)
  );

  if (original) {
    let thumb = imageResources.find(
      (x: IResource) =>
        x.model_id === model_id &&
        x.model === model &&
        x.conversion === preferedConversion &&
        x.parent_id === original.id
    );

    if (isPublic) {
      if (thumb) {
        return preparePublicAWSUrl(thumb);
      } else {
        return preparePublicAWSUrl(original);
      }
    } else {
      if (thumb) {
        return thumb.signed_url;
      } else {
        return original.signed_url;
      }
    }
  }
  return "";
};

export const getS3URLByName = (
  imageResources: IResource[],
  name: string,
  model: string,
  preferedConversion: string,
  primaryOnly: boolean,
  isPublic = true
) => {
  const original = imageResources.find(
    (x: IResource) =>
      x.name === name &&
      x.model === model &&
      x.conversion === "original" &&
      (!primaryOnly || x.primary === true)
  );

  if (original) {
    let thumb = imageResources.find(
      (x: IResource) =>
        x.model === model &&
        x.conversion === preferedConversion &&
        x.parent_id === original.id
    );

    if (isPublic) {
      if (thumb) {
        return thumb.signed_url;
      } else {
        return original.signed_url;
      }
    }
  }
  return "";
};
